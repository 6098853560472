<template>
  <TheLayout :page="page" :wizardSteps="wizardSteps">
    <WizardPage1 v-if="page === 1" v-model="value" v-model:anonymous="anonymousValue" :sadaqahUserDonatingType="sadaqahUserDonatingType" />
    <WizardPage2 v-if="page === 2" v-model="value" v-model:acceptTerms="acceptTerms" :paymentMethod="paymentMethod" :showCardPayment="showCardPayment" :sadaqahUserDonatingType="sadaqahUserDonatingType" @goTo="goTo" />
    <WizardPage3 v-if="page === 3" v-model="value" :sadaqahSavedReturn="sadaqahSavedReturn" @saveSadaqah="save" @sadaqahComplete="sadaqahComplete" v-model:isLoading="isLoadingValue"/>
    <Footer>
      <template v-slot:back>
        <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="goBack" :disabled="isLoading">
          <template v-slot:iconStart>
            <IconArrowBack color="white" />
          </template>
        </Button>
      </template>
      <template v-slot:next>
        <Button color="green_light" width="100" :btnText="nextText" icon="arrow" @buttonClicked="goNext" :disabled="nextDisabled" :isLoading="isLoading" v-if="page !== 3">
          <IconArrowForward class="inline-icon" />
        </Button>
      </template>
    </Footer>
  </TheLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TheLayout: defineAsyncComponent(() => import('../components/TheLayout.vue')),
    WizardPage1: defineAsyncComponent(() => import('./wizard/WizardPage1.vue')),
    WizardPage2: defineAsyncComponent(() => import('./wizard/WizardPage2.vue')),
    WizardPage3: defineAsyncComponent(() => import('./wizard/WizardPage3.vue')),
    Footer: defineAsyncComponent(() => import('../views/footer/Footer.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Daily Sadaqah Wizard',
  props: ['modelValue', 'paymentMethod', 'anonymous', 'sadaqahUserDonatingType', 'isLoading', 'wizardSteps', 'sadaqahSavedReturn'],
  emits: ['update:modelValue', 'update:paymentMethod', 'update:anonymous', 'update:isLoading', 'closeWizard', 'save', 'sadaqahComplete'],
  data () {
    return {
      page: 1,
      showPaymentMethod: false,
      showCardPayment: false,
      acceptTerms: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    anonymousValue: {
      get () {
        return this.anonymous
      },
      set (val) {
        this.$emit('update:anonymous', val)
      }
    },
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (val) {
        this.$emit('update:isLoading', val)
      }
    },
    nextDisabled () {
      if (this.page === 1) {
        if (!this.user) {
          if (this.anonymousValue.donorType === 'organisation' && !this.value.organisationName) {
            return true
          } else if (!this.value.donorName || !this.value.donorSurname || !this.value.email || !this.value.cellNumber || !this.anonymousValue.isValidNumber || !this.value.address.addressLine1 || !this.value.address.town || !this.value.address.country || !this.value.address.postalCode) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else if (this.page === 2) {
        if (!this.acceptTerms || this.acceptTerms === false || this.isLoading) {
          return true
        } else {
          return false
        }
      } else {
        if (!this.acceptTerms || this.acceptTerms === false || this.isLoading) {
          return true
        } else {
          return false
        }
      }
    },
    nextText () {
      if (this.page === 2) {
        return 'Setup Now'
      } else {
        return 'Next'
      }
    }
  },
  methods: {
    goBack () {
      if (this.page === 1) {
        this.$emit('closeWizard')
      } else {
        this.page = this.page - 1
      }
    },
    goNext () {
      this.page = this.page + 1
    },
    save () {
      this.$emit('save')
    },
    goTo (val) {
      console.log('GOTO', val)
      this.showPaymentMethod = false
      this.page = val
    },
    sadaqahComplete (val) {
      this.$emit('sadaqahComplete', val)
    }
  }
}

</script>
